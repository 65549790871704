import React from 'react'

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-copyright text-center py-3">
        ©2020 - All rights reserved<br />
        Made by Cristopher Lopez Santana
      </div>
    </div>
  )
}

export default Footer