const codeBaseURL = "https://github.com/ramclen";
const demoBaseURL = "https://ramclen.xyz";
export const projects = [
  {
    name: "Water Tracker App",
    img: "./img/water-tracker-app.png",
    description:
      "Water Tracker helps you to record your water consumptions in the day. Developed using React and AWS",
    codeLink: `${codeBaseURL}/water-tracker-app`,
    demoLink: `${demoBaseURL}/water-tracker-app`,
  },
  {
    name: "Supermarket Kata",
    img: "./img/supermarket-kata.png",
    description:
      "Supermarket Kata is an small implementation of supermaket kata using React following BDD",
    codeLink: `${codeBaseURL}/supermarket-kata`,
    demoLink: `${demoBaseURL}/supermarket-kata`,
  },
  {
    name: "Anime Searcher",
    img: "./img/anime-searcher.png",
    description:
      "Using an async autocomplete to search anime shows using Kitsu API. Developed using React",
    codeLink: `${codeBaseURL}/anime-searcher`,
    demoLink: `${demoBaseURL}/anime-searcher`,
  },
  {
    name: "Video Visualizer",
    img: "./img/video-searcher.png",
    description: "Video searcher using Youtube API. Developed using React ",
    demoLink: `${demoBaseURL}/video-searcher`,
    codeLink: `${codeBaseURL}/video-searcher`,
  },
  {
    name: "Streaming Video App",
    img: "./img/streamblog.png",
    description:
      "Streaming Video App that streams your own streaming using a RTMP server, and allows you to create, edit or delete your streams. Developed using React",
    codeLink: `${codeBaseURL}/video-streamer`,
  },
  {
    name: "Image searcher",
    img: "./img/image-searcher.png",
    description:
      "Image searcher show you different pictures when user types a hint in the search bar. Developed using React and Unsplash API ",
    demoLink: `${demoBaseURL}/Image-Searcher`,
    codeLink: `${codeBaseURL}/Image-Searcher`,
  },
  {
    name: "Shopping Cart",
    img: "./img/shopping-list.png",
    description:
      "Shopping list created using react and google spreadsheets API",
    demoLink: `${demoBaseURL}/shopping-cart`,
    codeLink: `${codeBaseURL}/ShoppingCart`,
  },
  {
    name: "Pong game",
    img: "./img/pong.png",
    description: "Pong game made using Vanilla JavaScript",
    demoLink: `${demoBaseURL}/Pong`,
    codeLink: `${codeBaseURL}/Pong`,
  },
  {
    name: "Snake game",
    img: "./img/snake-game.png",
    description: "Snake game made using Vanilla JavaScript",
    demoLink: `${demoBaseURL}/snake`,
    codeLink: `${codeBaseURL}/snake`,
  },
  {
    name: "Tetris game",
    img: "./img/tetris.png",
    description: "Tetris game made using Vanilla JavaScript",
    demoLink: `${demoBaseURL}/Tetris`,
    codeLink: `${codeBaseURL}/Tetris  `,
  },
];
